import React from "react";
import { useState } from "react";
import TrainerCard from "../Components/Trainers/TrainerCard";
import { useQuery, useIsFetching } from "react-query";
import Loader from "../Components/Loader";
import {
  Pagination,
  Stack,
  Title,
  Divider,
  Group,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { TrainersStyles } from "../Styles";
import { T, useT, useLang } from "../i18n/index";
import { getTrainers } from "../endpoints/api";
import NoData from "../Components/NoData";
import Dumbbell from "./../assets/dumbbell.png";

function Trainers() {
  const { classes } = TrainersStyles();
  const [activePage, setPage] = useState(1);
  const isFetching = useIsFetching();
  const lang = useLang();
  const t = useT();
  const pageSize = 8;
  const {
    data,
    status,
    isLoading,
    isError,
    isFetched,
    refetch,
    isPreviousData,
  } = useQuery(["trainers", activePage], () => getTrainers(activePage - 1), {
    keepPreviousData: true,
  });
  const [inputText, setInputText] = useState("");
  let inputHandler = (e: any) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  const filteredData =
    isFetched &&
    data.trainers.filter((el: any) => {
      //if no input the return the original
      if (inputText === "") {
        return el;
      }
      //return the item which contains the user input
      else {
        return el.name.toLowerCase().includes(inputText);
      }
    });
  return (
    <>
      <div className={classes.container}>
        <Stack
          sx={{ alignSelf: "flex-start" }}
          justify="flex-start"
          spacing={0}
          mb={20}
        >
          <div className="d-flex flex-column justify-content-start">
            <Title order={2} align="left">
              {t("TRAINERS")}{" "}
            </Title>
            <Divider color="#343741" size="xl" mt={7} sx={{ width: 70 }} />
          </div>
        </Stack>
        {data && data.count !== 0 && (
          <Group position="apart" className={classes.search}>
            <div>
              <Text size="xs" color="#1D1D1D">
                {t("SEARCH")}
              </Text>
              <TextInput
                onChange={inputHandler}
                radius={5}
                variant="unstyled"
                className={classes.searchInput}
                placeholder={t("SEARCH_PLACEHOLDER")}
              />
            </div>
          </Group>
        )}
        {/* map cards here */}
        <Group position="left" align="flex-start" mb={50} mt={50} spacing={50}>
          {data !== undefined &&
            filteredData &&
            filteredData?.length !== 0 &&
            filteredData?.map((item: any) => {
              return (
                <TrainerCard
                  key={item.id}
                  fetchedData={item}
                  fetchedStatus={isFetching}
                />
              );
            })}
        </Group>

        {data && data.count !== 0 && (
          <Pagination
            sx={{ alignSelf: "center" }}
            radius="xl"
            color="default.2"
            styles={{
              item: {
                borderColor: "#343741",
                fontSize: 14,
              },
            }}
            page={activePage}
            onChange={setPage}
            total={Math.floor(data.count / pageSize)}
          />
        )}
      </div>
      {data?.count == 0 && (
        <NoData img={Dumbbell} msg={t("NO_TRAINERS_FOUND")} />
      )}
      {isLoading && <Loader />}{" "}
    </>
  );
}

export default Trainers;
