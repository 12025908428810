import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Image,
  TextInput,
  NumberInput,
  Select,
  Grid,
  Button,
  Loader,
} from "@mantine/core";

import { DatePicker } from "@mantine/dates";
import * as yup from "yup";

import SectionTitle from "./Components/SectionTitle";
// import Loader from "../Loader";

import { useGetProfile, useGeUserPackages } from "../../api/auth";
import { useUpdate } from "../../utils/reactQuery";
import { useT } from "../../i18n";
import { useFormik } from "formik";
import { pathToUrl } from "../../utils/router";
import { apiRoutes } from "../../routes";

const UserProfile = () => {
  const { data: user, isLoading } = useGetProfile();
  const { data: packages } = useGeUserPackages();

  const [gender, setGender] = useState(user?.sex);
  const [touched, setTouched] = useState(false);
  const mutation = useUpdate(pathToUrl(apiRoutes.updateProfile));
  const t = useT();

  //Form validation
  const validationSchema = yup.object().shape({
    name: yup.string().required("This field is mandatory"),
    email: yup.string().required("This field is mandatory"),
    dob: yup.string().nullable(),
    mobile_no: yup
      .string()
      .required("This field is mandatory")
      .matches(/^[0-9]+$/, "Must be only digits"),
    sex: yup.string().required("This field is mandatory"),
  });

  const formik = useFormik({
    initialValues: {
      name: user ? user.name : "",
      email: user ? user.email : "",
      dob: user ? user.dob : null,
      mobile_no: user ? user.mobile_no : "",
      sex: user ? user.sex : true,
      home_location_id: user?.home_location_id,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const newUser = {
        ...user,
        name: values.name,
        email: values.email,
        dob: values.dob,
        mobile_no: values.mobile_no,
        sex: values.sex,
        home_location_id: values.home_location_id,
      };
      mutation.mutate({ user: newUser });
      setTouched(false);
      // updateProfile(values);
    },
  });

  // const updateProfile = (updatedValues: newValues) => {
  //   const newUser = {
  //     ...user,
  //     name: updatedValues.name,
  //     email: updatedValues.email,
  //     dob: updatedValues.dob,
  //     mobile_no: updatedValues.mobile_no,
  //     sex: updatedValues.sex,
  //   };
  //   mutation.mutate(newUser);
  // };

  const handleChangeValue = (e: any) => {
    if (!touched) setTouched(true);
    formik.handleChange(e);
  };

  const handleChangeDate = (e: any) => {
    if (!touched) setTouched(true);
    formik.setFieldValue("dob", new Date(e).toLocaleDateString("en-US"));
  };

  useEffect(() => {
    if (user) setGender(user.sex);
  }, [user]);

  useEffect(() => {
    formik.setFieldValue("sex", gender);
  }, [gender]);

  // console.log(user)
  // console.log(formik.values);
  if (isLoading) return <Loader color="orange" />;

  return (
    <Box sx={{ width: "90%" }}>
      {/*Tile */}
      <SectionTitle text={t("userProfile")} />

      {/*Profile Pic */}
      <div style={{ width: "102px", height: "102px", marginTop: "40px" }}>
        <Image
          alt="user image"
          src={user?.image}
          width="102"
          height={102}
          withPlaceholder
          styles={{
            image: { borderRadius: "50%", minHeight: "102px" },
          }}
        />
      </div>
      {/*Profile Pic */}

      {/*personal Info Form */}
      <Grid gutter="md" sx={{ marginTop: "10px" }}>
        <Grid.Col xs={12} md={6}>
          <TextInput
            placeholder="Enter Your Name"
            label={t("name")}
            name="name"
            value={formik.values.name}
            onChange={handleChangeValue}
            error={formik.errors.name}
          />
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <TextInput
            placeholder="Enter Your E-mail"
            label={t("email")}
            name="email"
            value={formik.values.email}
            onChange={handleChangeValue}
            error={formik.errors.email}
            disabled
          />
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <DatePicker
            placeholder="Pick date"
            label={t("dateOfBirth")}
            inputFormat="MM/DD/YYYY"
            labelFormat="MM/YYYY"
            value={formik.values.dob ? new Date(formik.values.dob) : null}
            onChange={handleChangeDate}
            error={formik.errors.dob}
          />
          {/* <TextInput
            placeholder="Enter Your Date of Birth"
            label={t("dateOfBirth")}
            name="dob"
            value={formik.values.dob ? formik.values.dob : ""}
            onChange={handleChangeValue}
            error={formik.errors.dob}
          /> */}
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <TextInput
            placeholder="Enter Your Mobile Number"
            label={t("mobileNumber")}
            name="mobile_no"
            value={formik.values.mobile_no}
            onChange={handleChangeValue}
            error={formik.errors.mobile_no}
          />
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Text size="sm">{t("gender")}</Text>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "10px",
            }}
          >
            <Button
              className="button-gender"
              sx={{
                backgroundColor: "#343741",
                border: "1px solid #707070",
                borderRadius: "16px",
                boxShadow: "0px 3px 6px #00000029",
                color: "#ffffff",
                fontSize: ".675rem",
                height: "32px",
                width: "98px",
                marginInlineEnd: "10px",
              }}

              /* onClick={() => {
              setGender(el.value);
              if (!touched) setTouched(true);
            }} */
            >
              {gender ? t("male") : t("female")}
            </Button>
          </div>
        </Grid.Col>
      </Grid>

      {/*Save Button */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <Button
          sx={{
            fontSize: ".875rem",
            height: "40px",
            width: "130px",
            marginInlineEnd: "10px",
          }}
          onClick={() => formik.handleSubmit()} //update info
          disabled={mutation.isLoading || !touched}
        >
          {mutation.isLoading && (
            <Loader color="orange" size="xs" sx={{ marginInlineEnd: "5px" }} />
          )}
          {t("save")}
        </Button>
      </div>
    </Box>
  );
};

// type newValues = {
//   name: string;
//   email: string;
//   dob: string;
//   mobile_no: number;
//   sex: boolean;
// };

export default UserProfile;
