import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { getTokenByPassword } from "../api/auth";

import { IconAlertCircle } from "@tabler/icons";
import { useLocalStorage } from "../utils/reactQuery";
import { navStyles } from "../Styles";

import {
  TextInput,
  PasswordInput,
  Card,
  Stack,
  Box,
  Button,
  Alert,
  Anchor,
  Title,
  Divider,
  Select,
} from "@mantine/core";
import { loginStyles } from "../Styles";
import { pageRoutes } from "../routes";

import { IconArrowNarrowRight } from "@tabler/icons";
import { Link } from "react-router-dom";
import { useT, useLang, useSetLang } from "../i18n/index";
import { useAuth } from "./../utils/auth";

function Login() {
  const { classes } = loginStyles();
  const navigate = useNavigate();
  const setLang = useSetLang();
  const [background, setBackground] = useState();

  const queryClient = useQueryClient();
  const [btnLoading, setBtnLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [club, setClub] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const t = useT();
  const lang = useLang();
  const auth = useAuth();
  // const [token, setToken] = useLocalStorage("token");

  useEffect(() => {
    fetch("https://evoxclubs.reno.systems/en/wp-json/api/portal-settings")
      .then((response) => response.json())
      .then((data) => {
        if (data.background) {
          setBackground(data.background);
        }
      });
  }, []);

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setBtnLoading(true);
    setError(false);
    try {
      await auth?.signin(username, password);
      if (auth?.user) {
        queryClient.invalidateQueries();
      } else {
        // setError(true);
      }
    } catch (e) {
      setError(true);
    } finally {
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(lang === "ar" ? "rtl" : "ltr");
  }, [lang]);

  useEffect(() => {
    if (auth?.user?.token) {
      navigate(pageRoutes.dashboard);
    }
  }, [auth]);

  return (
    <div
      className="tinted-image"
      style={{
        background: `linear-gradient(rgba(251, 73, 19, 0.4), rgba(251, 73, 19, 0.1)),
    url("${background}")`,
        backgroundSize: "cover",
      }}
    >
      <Card className={classes.card} radius="md">
        <Title className={classes.title} align="center" order={1} mb={30}>
          <div className="d-flex flex-column justify-content-start">
            <span>{t("LOGIN")}</span>
            <Divider color="#343741" size="xl" mt={15} sx={{ width: 84 }} />
          </div>
        </Title>
        <Stack
          spacing={25}
          align="center"
          className="login-input-wrapper"
          justify="center"
          style={{ width: "75%" }}
        >
          <TextInput
            value={username}
            onChange={(event) => setUsername(event.currentTarget.value)}
            placeholder={t("username")}
            // variant="unstyled"
            className={classes.input}
          />
          <PasswordInput
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            placeholder={t("password")}
            // variant="unstyled"
            className={classes.input}
          />
        </Stack>
        <div className="forgot-password-link">
          <Anchor
            className={classes.password}
            color="default.2"
            size={10}
            underline
            ml={255}
            mt={7}
            mb={30}
            component={Link}
            to="forgot-password"
          >
            {t("FORGOT_PASSWORD")}
          </Anchor>
        </div>
        <Button
          loading={btnLoading}
          onClick={(e: any) => onSubmit(e)}
          /*  rightIcon={<IconArrowNarrowRight size={30} stroke={1.5} />} */
          className={classes.button}
          /*  pl={30}
          styles={{ rightIcon: { marginLeft: 30 } }} */
        >
          {t("CONTINUE")}
        </Button>
        {error && (
          <Alert
            icon={<IconAlertCircle size={16} />}
            title="Error!"
            color="#343741"
            style={{ marginTop: "20px", width: "100%" }}
          >
            {t("Invalid_Credentials")}{" "}
            <Anchor
              color="default.2"
              size={12}
              underline
              mt={1}
              mb={1}
              href={`https://evoxclubs.reno.systems/${lang}/join/`}
              target="_blank"
            >
              {t("NO_ACCOUNT")}
            </Anchor>
          </Alert>
        )}
        <Anchor
          onClick={() => setLang(lang === "ar" ? "en" : "ar")}
          variant="text"
          underline
          size={16}
          sx={{
            borderLeft: "2px solid white",
            fontFamily: "Cairo",
            marginTop: "15px",
          }}
        >
          {t("LANGUAGE")}
        </Anchor>
      </Card>
    </div>
  );
}

export default Login;
