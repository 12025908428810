import axios from "axios";

import { QueryFunction, QueryFunctionContext } from "react-query";
import { apiRoutes } from "../routes";
import { useLocalStorage } from "../utils/reactQuery";

// const [token] = useLocalStorage("token");

const api = axios.create({
  baseURL: "https://evoxclubs.reno.systems/en/wp-json/api/",
  headers: {
    accept: "application/json",
  },
});

export const setAxiosTokenInterceptor = async (
  accessToken: string | null
): Promise<void> => {
  if (accessToken) {
    api.defaults.headers.common["Authorization"] = accessToken;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

export const getClasses = async (
  startDate: string,
  endDate: string,
  location_id: number
) => {
  const response = await api.get(
    `/occurrences?since=${startDate}T00:00:00Z&till=${endDate}T23:59:59Z&page=0&limit=10&location_id=${location_id}`
  );
  return response.data;
};

export const getAppointments = async (
  startDate: string,
  endDate: string,
  userID: number | undefined
) => {
  const response = await api.get(
    `/appointments?since=${startDate}T00:00:00Z&till=${endDate}T23:59:59Z&page=0&limit=10&participant_id=${userID}`
  );
  return response.data;
};

export const getTransactions = async () => {
  const response = await api.get(`transactions`);
  return response.data;
};

export const getPackages = async (
  club_id: number | undefined,
  location_id: number | undefined
) => {
  const response = await api.get(
    `packages?club_id=${club_id}&location_id=${location_id}`
  );
  return response.data;
};

export const getTestPackages = async (
  club_id: number | undefined,
  location_id: number | undefined
) => {
  const response = await api.get(
    `test-packages?club_id=${club_id}&location_id=${location_id}`
  );
  return response.data;
};

export const getRenwalPackages = async (
  club_id: number | undefined,
  location_id: number | undefined
) => {
  const response = await api.get(
    `renewal-packages?club_id=${club_id}&location_id=${location_id}`
  );
  return response.data;
};

export const getFounderRenwalPackages = async (club_id: number) => {
  const response = await api.get(`founder-renewal-packages?club_id=${club_id}`);
  return response.data;
};
export const getUserPackages = async () => {
  const response = await api.get(apiRoutes.getPackages);
  return response.data;
};

export const getUserExpiredPackages = async () => {
  const response = await api.get(apiRoutes.getExpiredPackages);
  return response.data;
};

export const getUserProfile = async () => {
  const response = await api.get(apiRoutes.getProfile);
  return response.data;
};
export const getPTPackages = async (
  club_id: number | undefined,
  location_id: number | undefined
) => {
  const response = await api.get(
    `pt-packages?club_id=${club_id}&location_id=${location_id}`
  );
  return response.data;
};

export const getTrainerClasses = async (
  startDate: string,
  endDate: string,
  trainerID: any
) => {
  const response = await api.get(
    `trainer-occurrences?since=${startDate}T00:00:00Z&till=${endDate}T23:59:59Z&page=0&limit=10&trainer_id=${trainerID}`
  );
  return response.data;
};

export const getTrainers = async (page: any = 0, pageSize: number = 8) => {
  const response = await api.get(`trainers?page=${page}&limit=${pageSize}`);
  return response.data;
};

export const getTrainerDetails = async (trainerID: any) => {
  const response = await api.get(`trainers/${trainerID}`);
  return response.data;
};

export const getLocation = async (locationId: any) => {
  const response = await api.get(`locations/${locationId}`);
  return response.data;
};

export const getLocations = async (clubId: any) => {
  const response = await api.get(`gym-locations/${clubId}`);
  return response.data;
};
export default api;
