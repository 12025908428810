import { Anchor, Group, MediaQuery, Text } from "@mantine/core";
import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { useT } from "../i18n";
import { useQuery, useIsFetching } from "react-query";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import { enGB, ar } from "date-fns/locale";
import { T, useSetLang, useLang } from "../i18n/index";
import {
  getPackages,
  getPTPackages,
  getUserPackages,
  getRenwalPackages,
  getFounderRenwalPackages,
} from "../endpoints/api";

const NotificationBar = () => {
  const t = useT();
  const lang = useLang();
  const locale = lang === "ar" ? ar : enGB;
  const {
    data: userPackages,
    isLoading: isUserPackagesLoading,
    isFetched: isUserPackagesFetched,
  } = useQuery(["userPackages"], () => getUserPackages(), {});

  const pt_induction = userPackages?.client_service_packages?.find(
    (s: any) => s.service_package.id == 253
  );

  return (
    <>
      {" "}
      <MediaQuery
        className="notification-bar-wrapper-mobile"
        largerThan={500}
        styles={{ display: "none" }}
      >
        <Group
          position="center"
          align="center"
          spacing={5}
          sx={{ backgroundColor: "white" }}
        >
          {pt_induction && pt_induction.length !== 0 && (
            <Text
              size={14}
              className="d-flex flex-column m-3 justify-content-center align-items-center text-center"
            >
              {" "}
              <div>
                <span className="mr-1">{t("NOTIFICATION_1")}</span>
                <span
                  className="mx-2"
                  style={{ fontWeight: "bolder", color: "#343741" }}
                >
                  {pt_induction?.total_credits}
                </span>

                <span>
                  {t("NOTIFICATION_2")},
                  {pt_induction?.expires_at &&
                    formatDistance(
                      new Date(pt_induction?.expires_at),
                      new Date(),
                      {
                        addSuffix: true,
                        locale,
                      }
                    )}{" "}
                </span>
              </div>
              <div>
                {/*  <Link
                className="ml-1 primary-activ-color"
                to="/appointments"
              >
                {t("NOTIFICATION_3")}
              </Link>
              <span>{t("NOTIFICATION_4")}</span> */}
              </div>
            </Text>
          )}
        </Group>
      </MediaQuery>
      {pt_induction && pt_induction.length !== 0 && (
        <MediaQuery smallerThan={500} styles={{ display: "none" }}>
          <Group
            position="center"
            align="center"
            spacing={5}
            sx={{ backgroundColor: "white", height: 40 }}
          >
            <Text
              sx={{
                backgroundColor: "#343741",
                width: 25,
                height: 25,
                borderRadius: 100,
              }}
              size={16}
              align="center"
              weight="bolder"
              color="white"
              style={{ display: "flex", justifyContent: "center" }}
            >
              i
            </Text>{" "}
            <Text size={14}>
              {" "}
              <span className="mr-1">{t("NOTIFICATION_1")}</span>
              <span
                className="mx-2"
                style={{ fontWeight: "bolder", color: "#343741" }}
              >
                {pt_induction?.total_credits}
              </span>
              <span>
                {t("NOTIFICATION_2")}{" "}
                {pt_induction?.expires_at &&
                  formatDistance(
                    new Date(pt_induction?.expires_at),
                    new Date(),
                    {
                      addSuffix: true,
                      locale,
                    }
                  )}{" "}
              </span>
              {/*  <Link className="ml-1 mr-1 primary-activ-color" to="/appointments">
              {t("NOTIFICATION_3")}
            </Link> */}
              {/* {t("NOTIFICATION_4")} */}
            </Text>
          </Group>
        </MediaQuery>
      )}
    </>
  );
};

export default NotificationBar;
