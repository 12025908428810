import {
  Stack,
  Title,
  Divider,
  Card,
  Box,
  Avatar,
  Text,
  Group,
  Button,
  Skeleton,
  MediaQuery,
} from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import { TrainersStyles } from "../../Styles";
import { useQuery, useIsFetching } from "react-query";
import { getTrainerDetails, getTrainerClasses } from "../../endpoints/api";
import Loader from "../../Components/Loader";

import ClassCard from "../../Components/ClassCard";
import Calendar from "../../Components/Calendar";
import { format, add, eachDayOfInterval, addDays } from "date-fns";
import { T, useT, useSetLang, useLang } from "../../i18n/index";

import { useState, useMemo, useEffect } from "react";

const TrainerDetails = () => {
  const location = useLocation();
  const isFetching = useIsFetching();
  const lang = useLang();
  const renderCalendar = false;
  const trainerID = location.state;
  const [count, setCount] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const initialDate = addDays(new Date(), count * 7);

  function getRequestedDays(startDate = initialDate) {
    const aWeekFromNow = addDays(startDate, 6);
    const thisWeek = eachDayOfInterval({ start: startDate, end: aWeekFromNow });

    return thisWeek;
  }
  const getSelectedDays = useMemo(() => getRequestedDays(initialDate), [count]);

  const { classes } = TrainersStyles();
  const { data, status, isLoading, isError, isFetched, refetch } = useQuery(
    "trainer-details",
    () => getTrainerDetails(trainerID)
  );

  const {
    data: trainerClasses,
    status: trainerClassesStatus,
    isLoading: trainerClassesIsLoading,
    isError: trainerClassesIsError,
    isFetched: trainerClassesIsFetched,
    refetch: trainerClassesRefetch,
  } = useQuery("trainer-classes", () =>
    getTrainerClasses(
      format(getSelectedDays[activeIndex], "yyyy-MM-dd"),
      format(getSelectedDays[activeIndex], "yyy-MM-dd"),
      trainerID
    )
  );

  useEffect(() => {
    refetch();
  }, [count, activeIndex]);

  const t = useT();
  return (
    <>
      {isFetched && !isLoading && data !== undefined && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{ alignSelf: "flex-start" }}
            ml={30}
            mt={10}
            justify="flex-start"
            spacing={0}
            mb={20}
          >
            <div>
              <Title order={2} align="left">
                {data.trainer.name}
              </Title>
              <Divider color="#343741" size="xl" mt={7} sx={{ width: 70 }} />
            </div>
          </Stack>
          <MediaQuery smallerThan={500} styles={{ display: "none" }}>
            <div className={classes.detailsCard}>
              <Avatar
                styles={{
                  image: {
                    borderRadius: 200,
                  },
                }}
                sx={{ padding: 20, backgroundColor: "white" }}
                className={classes.detailsAvatar}
                src={data.trainer.image}
                alt="trainer"
                size={280}
                radius={200}
              ></Avatar>
              <Stack align="flex-start" spacing={15} pr={60}>
                <Skeleton
                  radius="md"
                  width="100%"
                  p={8}
                  visible={isFetching === 1 ? true : false}
                >
                  <Text sx={{ width: 850 }} size={14}>
                    {data.trainer.bio}
                  </Text>{" "}
                  <Title mt={40} order={2} align="left">
                    {t("QUALIFICATIONS")}
                  </Title>
                  <Text
                    className="qualifications-wrapper"
                    sx={{ width: 850 }}
                    size={14}
                  >
                    {data.trainer.qualifications}
                  </Text>{" "}
                </Skeleton>
                <Group
                  position="left"
                  spacing={10}
                  noWrap
                  sx={{ width: "100%" }}
                >
                  <Skeleton
                    radius="md"
                    width="35%"
                    visible={isFetching === 1 ? true : false}
                  >
                    <Group spacing={15} noWrap>
                      <Text size={12}>{t("EMAIL")}</Text>{" "}
                      <Text size={12} color="default.8">
                        {data.trainer.email}
                      </Text>{" "}
                    </Group>
                  </Skeleton>
                  <Skeleton
                    radius="md"
                    width="30%"
                    visible={isFetching === 1 ? true : false}
                  ></Skeleton>
                </Group>
                <Group position="left" noWrap>
                  <Skeleton
                    radius="md"
                    width="75%"
                    visible={isFetching === 1 ? true : false}
                  ></Skeleton>

                  {/* <Button
                    sx={{ width: 200 }}
                    component={Link}
                    to="/trainers/createappointment"
                  >
                    {t("BOOK_APT")}
                  </Button> */}
                </Group>
              </Stack>
            </div>
          </MediaQuery>

          <MediaQuery largerThan={500} styles={{ display: "none" }}>
            <div className={classes.detailsCard}>
              <Avatar
                styles={{
                  image: {
                    borderRadius: 100,
                  },
                }}
                sx={{ padding: 10, backgroundColor: "white" }}
                className={classes.detailsAvatar}
                src={data.trainer.image}
                alt="trainer"
                size={150}
                radius={100}
              ></Avatar>
              <Stack align="center" spacing={5}>
                <Skeleton
                  radius="md"
                  width="100%"
                  p={8}
                  visible={isFetching === 1 ? true : false}
                >
                  <Text sx={{ width: 300, height: 150 }} size={12}>
                    {data.trainer.bio}
                  </Text>{" "}
                </Skeleton>
                <Group
                  position="left"
                  spacing={25}
                  noWrap
                  sx={{ width: "100%" }}
                >
                  <Skeleton
                    radius="md"
                    width="30%"
                    visible={isFetching === 1 ? true : false}
                  >
                    <Stack spacing={5} align="center">
                      <Text size={10}>{t("MOBILE")}</Text>{" "}
                      <Text size={10} color="default.8">
                        057076827
                      </Text>{" "}
                    </Stack>
                  </Skeleton>
                  <Skeleton
                    radius="md"
                    width="60%"
                    visible={isFetching === 1 ? true : false}
                  >
                    <Stack spacing={5} align="center">
                      <Text size={10}>{t("EMAIL")}</Text>{" "}
                      <Text size={10} color="default.8">
                        {data.trainer.email}
                      </Text>{" "}
                    </Stack>
                  </Skeleton>
                  <Skeleton
                    radius="md"
                    width="30%"
                    visible={isFetching === 1 ? true : false}
                  >
                    {/*  <Stack spacing={5} align="center">
                      <Text size={10}>{t("COUNTRY")}</Text>{" "}
                      <Text size={10} color="default.8">
                        Saudi Arabia
                      </Text>
                    </Stack> */}
                    <Stack align="center" spacing={5} mt={10} mr={10}>
                      <Text size={10}>{t("LOCATION")}</Text>{" "}
                      <Text
                        color="white"
                        sx={{
                          width: 88,
                          height: 27,
                          backgroundColor: "#343741",
                          borderRadius: 100,
                          paddingLeft: 14,
                          paddingTop: 5,
                        }}
                        size={10}
                      >
                        Al Malqa
                      </Text>
                    </Stack>{" "}
                  </Skeleton>
                </Group>
                <Group position="center" noWrap>
                  <Skeleton
                    radius="md"
                    width="75%"
                    visible={isFetching === 1 ? true : false}
                  ></Skeleton>

                  {/* <Button
                  <Button
                    className="font-size-14"
                    sx={{
                      width: 100,
                      height: 35,
                      marginLeft: 50,
                      marginTop: 20,
                    }}
                    component={Link}
                    to="/trainers/createappointment"
                  >
                    {t("BOOK_NOW")}
                  </Button> */}
                </Group>
              </Stack>
            </div>
          </MediaQuery>
        </div>
      )}
      {isLoading || (trainerClassesIsLoading && <Loader />)}

      <Divider color="transparent" size="lg" mt={25} sx={{ width: 70 }} />

      {trainerClassesIsFetched && renderCalendar && (
        <Calendar
          fetchedData={trainerClasses.occurrences}
          loadingStatus={trainerClassesStatus}
          fetchedStatus={trainerClassesIsFetched}
          getSelectedDays={getSelectedDays}
          setCount={setCount}
          count={count}
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
          lang={lang}
        />
      )}

      {trainerClasses &&
        renderCalendar &&
        !trainerClassesIsLoading &&
        trainerClasses !== undefined &&
        trainerClasses.occurrences?.length !== 0 && (
          <ClassCard
            fetchedData={trainerClasses.occurrences}
            fetchedStatus={isFetching}
            input={""}
            canBookClass={false}
          />
        )}
    </>
  );
};

export default TrainerDetails;
