import { useState, useEffect } from "react";
import { PackageInterface } from "../../interfaces/index";
import { useMediaQuery } from "@mantine/hooks";
import { format } from "date-fns";

import { showNotification } from "@mantine/notifications";

import { IconCheck, IconX } from "@tabler/icons-react";

import {
  Box,
  Grid,
  Center,
  Text,
  Button,
  Stack,
  Divider,
  Modal,
  Title,
} from "@mantine/core";

import SectionTitle from "./Components/SectionTitle";
import Loader from "../Loader";

import { useGeUserPackages } from "../../api/auth";
import { pathToUrl } from "../../utils/router";
import { apiRoutes } from "../../routes";

import { useT } from "../../i18n/index";
import api from "../../endpoints/api";
import getTime from "date-fns/getTime";

const Subscriptions = () => {
  const { data, isLoading, refetch } = useGeUserPackages();
  // const mutation = useUpdate(pathToUrl(apiRoutes.terminatePackage));
  const largeScreen = useMediaQuery("(min-width: 900px)");
  const [subscriptions, setSubscriptions] = useState<PackageInterface[]>([]);
  const [opened, setOpened] = useState(false);
  const [terminateID, setTerminateID] = useState<number>();
  const [cancelLoading, setCancelLoading] = useState(false);
  const isValidPackage = ({ service_package, refunded }: any) => {
    return (
      !refunded &&
      (service_package?.subscribable ||
        service_package?.name.toLowerCase().includes("access") ||
        service_package?.name.toLowerCase().includes("free") ||
        service_package?.name.toLowerCase().includes("تجديد") ||
        service_package?.name.toLowerCase().startsWith("اشتراك"))
    );
  };

  const t = useT();

  const handleTermination = () => {
    setCancelLoading(true);

    const subscriptionToTerminate = subscriptions.find(
      (sub) => sub.id === terminateID
    );
    const expires_at: string | undefined | Date =
      subscriptionToTerminate?.expires_at;
    const message = `${t("ACCESS_TILL")} ${
      expires_at && format(new Date(expires_at), "yyyy-MM-dd")
    }`;

    const expires_at_time = expires_at && getTime(new Date(expires_at));

    console.log(expires_at_time);

    api
      .put(
        pathToUrl(apiRoutes.terminatePackage, {
          id: terminateID,
        }),
        { expires_at: expires_at }
      )
      .then((respone: any) => {
        console.log(respone);
        if (!respone!.data?.errors.length) {
          refetch();
          setCancelLoading(false);
          setOpened(false);
          showNotification({
            title: t("SUBSCRIBTION_CANCELLED"),
            message: message,
            autoClose: 5000,
            icon: <IconCheck size="1.1rem" />,
            color: "teal",
          });
        }
      });
  };

  useEffect(() => {
    if (!isLoading && data) {
      const subs = data.client_service_packages.filter((pkg) => {
        return isValidPackage(pkg);
      });
      setSubscriptions(subs);
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Box sx={{ width: "90%" }}>
      {/*Title */}
      <SectionTitle text={t("subscriptions")} />
      {subscriptions.length ? (
        <>
          <Box sx={{ marginTop: "50px" }}>
            {subscriptions.map((sub) => (
              <div key={sub.id} style={{ marginBottom: "40px" }}>
                <Grid
                  gutter="xl"
                  justify="center"
                  sx={{
                    background: "#F5F5F5 0% 0% no-repeat padding-box",
                    borderRadius: "24px",
                    padding: "20px",
                  }}
                >
                  <Grid.Col xs={12} md={8}>
                    <Center sx={{ height: "100%" }}>
                      <div>
                        <Text
                          sx={{
                            textTransform: "uppercase",
                            fontWeight: 550,
                            fontSize: "1rem",
                          }}
                        >
                          {sub.service_package.name}
                        </Text>
                        <Text
                          sx={{
                            textTransform: "uppercase",
                            color: "#1D1D1D",
                            fontSize: ".875rem",
                            fontWeight: 300,
                          }}
                        >
                          {sub.service_package.description}
                        </Text>
                      </div>
                    </Center>
                  </Grid.Col>
                  <Grid.Col xs={12} md={4}>
                    {/*Package Price */}
                    <Grid justify="space-between">
                      <Grid.Col xs={6} sx={{ color: "#1D1D1D" }}>
                        <Text size="xs">{t("packagePrice")}</Text>
                      </Grid.Col>
                      <Grid.Col xs={6}>
                        <Text size="xs" sx={{ color: "#919191" }}>
                          {sub.service_package.custom_price?.amount / 100} SAR
                        </Text>
                      </Grid.Col>
                    </Grid>

                    {/*Usage */}
                    <Grid justify="space-between">
                      <Grid.Col xs={6} sx={{ color: "#1D1D1D" }}>
                        <Text size="xs">{t("usage")}</Text>
                      </Grid.Col>
                      <Grid.Col xs={6}>
                        <Text size="xs" sx={{ color: "#919191" }}>
                          {`${sub.used_credits} / ${sub.total_credits}`}
                        </Text>
                      </Grid.Col>
                    </Grid>

                    {/*Purchase Date */}
                    <Grid justify="space-between">
                      <Grid.Col xs={6} sx={{ color: "#1D1D1D" }}>
                        <Text size="xs">{t("purchaseDate")}</Text>
                      </Grid.Col>
                      <Grid.Col xs={6}>
                        <Text size="xs" sx={{ color: "#919191" }}>
                          {format(new Date(sub.assigned_at), "yyyy-MM-dd")}
                        </Text>
                      </Grid.Col>
                    </Grid>

                    {/*Exipry Date */}
                    <Grid justify="space-between">
                      <Grid.Col xs={6} sx={{ color: "#1D1D1D" }}>
                        <Text size="xs">{t("expiryDate")}</Text>
                      </Grid.Col>
                      <Grid.Col xs={6}>
                        <Text size="xs" sx={{ color: "#919191" }}>
                          {format(new Date(sub.expires_at), "yyyy-MM-dd")}
                        </Text>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                  <Grid
                    gutter="xl"
                    justify="flex-end"
                    sx={{
                      padding: "20px",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setOpened(true);
                        setTerminateID(sub.id);
                      }}
                      mt={10}
                    >
                      {t("CANCEL")}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            ))}
          </Box>

          <Modal
            opened={opened}
            onClose={() => {
              setOpened(false);
              setCancelLoading(false);
            }}
            centered
            size={largeScreen ? "50%" : "100%"}
            overlayColor="white"
            overlayOpacity={0}
            overlayBlur={0}
            closeOnClickOutside={true}
            lockScroll={false}
            styles={{
              modal: {
                backgroundColor: "rgba(255,255,255,1)",
                border: "1px solid grey",
              },

              close: {
                backgroundColor: "#343741",
                color: "white",
                width: 45,
                height: 45,
                borderRadius: 100,
                position: "relative",
                bottom: 35,
                left: 20,
                "&:hover": {
                  backgroundColor: "#343741",
                },
              },
            }}
          >
            <Stack justify="start" align="center">
              <Stack mb={10} justify="flex-start" spacing={0}>
                <div className="d-flex flex-column justify-content-start purchase-modal-title">
                  <Title order={2}>{t("CANCEL_SUBSCRIPTION_TITLE")}</Title>
                  <Divider
                    color="#343741"
                    size="xl"
                    mt={25}
                    sx={{ width: 84, alignSelf: "center" }}
                  />
                </div>
              </Stack>
              <div className="payment-method-wrapper">
                <Stack className="d-flex flex-column justify-content-center align-items-center">
                  <Button
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                    onClick={handleTermination}
                    disabled={cancelLoading}
                    loading={cancelLoading}
                  >
                    {t("CONFIRM")}
                  </Button>
                </Stack>
              </div>
            </Stack>
          </Modal>
        </>
      ) : (
        <div className="pt-5">
          <Text>{t("NO_ASSIGNED_PACKAGES")}</Text>
        </div>
      )}
    </Box>
  );
};

export default Subscriptions;
