import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Text,
  PasswordInput,
  Button,
  Space,
  Loader,
} from "@mantine/core";
import * as yup from "yup";

import SectionTitle from "./Components/SectionTitle";
import { useNavigate } from "react-router-dom";
import { useGetProfile } from "../../api/auth";
import { pathToUrl } from "../../utils/router";
import { apiRoutes } from "../../routes";
import { useT, useSetLang } from "../../i18n/index";
import { useUpdate } from "../../utils/reactQuery";
import { useFormik } from "formik";

const Settings = () => {
  const { data: user, isLoading } = useGetProfile();
  const setLang = useSetLang();
  const [language, setLanguage] = useState(user?.locale);
  const [touched, setTouched] = useState(false);
  const [profileTouched, setProfileTouched] = useState(false);
  const passwordMutation = useUpdate(pathToUrl(apiRoutes.changePassword));
  const profileMutation = useUpdate(pathToUrl(apiRoutes.updateProfile));

  const t = useT();
  let navigate = useNavigate();
  //Form validation
  const validationSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .required("Please enter your current password"),
    newPassword: yup.string().required("Please enter a new password"),
    newPasswordConfirm: yup
      .string()
      .required("Please confirm your new password"),
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      // console.log("submitted");
      const newCredintials = {
        current_password: values.currentPassword,
        password: values.newPassword,
        password_confirmation: values.newPasswordConfirm,
      };

      passwordMutation.mutate({ user: newCredintials });
      setTouched(false);
      formik.resetForm();
    },
  });

  const profileFormik = useFormik({
    initialValues: {
      language: language,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log("submitted");
      const newUser = { ...user, locale: values.language };
      profileMutation.mutate(newUser, {
        onSuccess: () => setLang(language === "en" ? "en" : "ar"),
      });
      setProfileTouched(false);
    },
  });

  const handleChangeValue = (e: any) => {
    if (!touched) setTouched(true);
    formik.handleChange(e);
  };

  const handleSubmission = async () => {
    if (touched) formik.handleSubmit();

    if (profileTouched && !Object.keys(formik.errors).length) {
      profileFormik.handleSubmit();
      setProfileTouched(false);
      navigate("/account-settings/Success");
    }
  };

  useEffect(() => {
    profileFormik.setFieldValue("language", language);
  }, [language]);

  if (isLoading) return <Loader color="orange" />;
  // console.log(user);
  return (
    <Grid gutter="xl">
      {/*settings */}
      <Grid.Col xs={12} md={6}>
        {/*Title */}
        <SectionTitle text={t("settings")} />
        {/*passsword */}
        <Grid gutter="md" sx={{ marginTop: "20px" }}>
          <Grid.Col xs={10}>
            <PasswordInput
              placeholder={t("currentPassword")}
              label={t("currentPassword")}
              name="currentPassword"
              value={formik.values.currentPassword}
              onChange={handleChangeValue}
              error={formik.errors.currentPassword}
            />
          </Grid.Col>
          <Grid.Col xs={10}>
            <PasswordInput
              placeholder={t("newPassword")}
              label={t("newPassword")}
              name="newPassword"
              value={formik.values.newPassword}
              onChange={handleChangeValue}
              error={formik.errors.newPassword}
            />
          </Grid.Col>
          <Grid.Col xs={10}>
            <PasswordInput
              placeholder={`${t("newPassword")} (${t("confirm")})`}
              label={`${t("newPassword")} (${t("confirm")})`}
              name="newPasswordConfirm"
              value={formik.values.newPasswordConfirm}
              onChange={handleChangeValue}
              error={formik.errors.newPasswordConfirm}
            />
          </Grid.Col>

          <Grid.Col xs={12}>
            <SectionTitle text={t("lang")} />
            <Space h="md" />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "10px",
              }}
            >
              {["en", "ar"].map((el) => (
                <Button
                  key={el}
                  sx={{
                    backgroundColor: language === el ? "#343741" : "#FFFFFF",
                    border: "1px solid #707070",
                    borderRadius: "16px",
                    boxShadow: "0px 3px 6px #00000029",
                    color: language === el ? "#ffffff" : "#AAAAAA",
                    fontSize: ".675rem",
                    height: "32px",
                    width: "98px",
                    marginInlineEnd: "10px",
                  }}
                  onClick={() => {
                    setLanguage(el);
                    if (!profileTouched) setProfileTouched(true);
                  }}
                >
                  {t(el === "en" ? "en" : "ar")}
                </Button>
              ))}
            </div>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      {/*credit cards */}
      <Grid.Col xs={12} md={6}>
        {/*Title */}
        {/* <SectionTitle text={t("creditCards")} /> */}
        {/*credit cards list */}
        {/* <Box sx={{ marginTop: "20px" }}>
          <Text
            size="xs"
            sx={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 3px 6px #00000029",
              border: "1px solid #E0E0E0",
              padding: "5px",
            }}
          >
            Visa****16555
          </Text>
        </Box> */}
      </Grid.Col>

      {/*Save */}
      <Grid.Col xs={12}>
        {/*Save Button */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              fontSize: ".875rem",
              height: "40px",
              width: "130px",
              marginInlineEnd: "10px",
            }}
            onClick={handleSubmission} //update info
            disabled={
              passwordMutation.isLoading ||
              profileMutation.isLoading ||
              (!profileTouched && !touched)
            }
          >
            {(passwordMutation.isLoading || profileMutation.isLoading) && (
              <Loader
                color="orange"
                size="xs"
                sx={{ marginInlineEnd: "5px" }}
              />
            )}
            {t("save")}
          </Button>
        </div>
      </Grid.Col>
    </Grid>
  );
};

export default Settings;
