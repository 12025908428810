import React from "react";

import { Text } from "@mantine/core";

type SectionTitleProps = {
  text: string;
};

/**
 *
 * @param props
 * @param props.text section title
 * @returns
 */
const SectionTitle = (props: SectionTitleProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Text
        size="lg"
        sx={{
          fontFamily: "OCR-B",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        {props.text}
      </Text>
      <div
        style={{
          borderBottom: "5px solid #343741",
          width: "70px",
          marginInlineStart: "5px",
        }}
      ></div>
    </div>
  );
};

export default SectionTitle;
