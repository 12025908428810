import React, { useState, useEffect } from "react";
import { Container, Grid, Text, Box } from "@mantine/core";
import UserProfile from "./UserProfile";
import TransactionsHistory from "./TransactionsHistory";
import AssignedPackages from "./AssignedPackages";
import Subscriptions from "./Subscriptions";
import Settings from "./Settings";
import ChangeLocation from "./ChangeLocation";
import { useT } from "../../i18n/index";

const Tabs: string[] = [
  "userProfile",
  "transactionsHistory",
  "assignedPackages",
  "subscriptions",
  "settings",
  // "changeLocation",
];

const AccountSettings = () => {
  const [currentTab, setCurrentTab] = useState("userProfile");
  const [tabComponent, setTabComponent] = useState(<UserProfile />);
  const t = useT();

  //change tab handler
  const handleChangeTab = (e: any) => {
    const tab = e.currentTarget.id;
    setCurrentTab(tab);
  };

  useEffect(() => {
    switch (currentTab) {
      case "userProfile":
        setTabComponent(<UserProfile />);
        break;
      case "transactionsHistory":
        setTabComponent(<TransactionsHistory />);
        break;
      case "assignedPackages":
        setTabComponent(<AssignedPackages />);
        break;
      case "subscriptions":
        setTabComponent(<Subscriptions />);
        break;
      case "settings":
        setTabComponent(<Settings />);
        break;
      case "changeLocation":
        setTabComponent(<ChangeLocation />);
        break;
      default:
        setTabComponent(<UserProfile />);
        break;
    }
  }, [currentTab]);

  return (
    <>
      <Container
        fluid
        sx={(theme) => ({
          padding: "0px 60px 100px 60px",
          [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            padding: "0px 40px 100px 40px",
          },
        })}
      >
        <Grid gutter="xs">
          <Grid.Col xs={12} md={3} lg={2}>
            <Box
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "20px",
                minHeight: "100%",
              }}
              px="lg"
              py="md"
            >
              {Tabs.map((tab) => (
                <Text
                  key={tab}
                  size="xs"
                  sx={{
                    padding: "15px 0px 15px 0px",
                    cursor: "pointer",
                    color: currentTab === tab ? "black" : "#343741",
                    ...(currentTab === tab && {
                      borderBottom: "3px solid #343741",
                      marginBottom: "10px",
                    }),
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  id={tab}
                  onClick={handleChangeTab}
                >
                  {t(
                    tab === "transactionsHistory"
                      ? "transactionsHistory"
                      : tab === "subscriptions"
                      ? "subscriptions"
                      : tab === "settings"
                      ? "settings"
                      : tab === "assignedPackages"
                      ? "assignedPackages"
                      : tab === "changeLocation"
                      ? "changeLocation"
                      : "userProfile"
                  )}
                </Text>
              ))}
            </Box>
          </Grid.Col>
          <Grid.Col xs={12} md={9} lg={10}>
            <Box
              sx={{
                minHeight: "100%",
                backgroundColor: "#ffffff",
                borderRadius: "20px",
              }}
              px="xl"
              py="xl"
            >
              {tabComponent}
            </Box>
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};

export default AccountSettings;
