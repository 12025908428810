import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../api/auth";

import { IconAlertCircle } from "@tabler/icons";
import { useLocalStorage } from "../utils/reactQuery";

import {
  Input,
  TextInput,
  PasswordInput,
  Card,
  Stack,
  Box,
  Button,
  Alert,
  Anchor,
  Title,
  Divider,
  Select,
} from "@mantine/core";
import { loginStyles } from "../Styles";
import { pageRoutes } from "../routes";

import { IconArrowNarrowRight } from "@tabler/icons";
import { Link } from "react-router-dom";
import { useT, useLang, useSetLang } from "../i18n/index";
import { useAuth } from "./../utils/auth";

function ForgotPassword() {
  const { classes } = loginStyles();
  const navigate = useNavigate();
  const setLang = useSetLang();
  const [background, setBackground] = useState();

  const queryClient = useQueryClient();
  const [btnLoading, setBtnLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [club, setClub] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const t = useT();
  const lang = useLang();
  const auth = useAuth();
  // const [token, setToken] = useLocalStorage("token");

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    
    if (username != "") {
      setBtnLoading(true);
      setError(false);
      try {
        await forgotPassword(username);
        setSuccess(true);
      } catch (e) {
        setError(true);
      } finally {
        setBtnLoading(false);
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    document.body.removeAttribute("class");
    document.body.classList.add(lang === "ar" ? "rtl" : "ltr");
  }, [lang]);

  useEffect(() => {
    fetch("https://evoxclubs.reno.systems/en/wp-json/api/portal-settings")
      .then((response) => response.json())
      .then((data) => {
        if (data.background) {
          setBackground(data.background);
        }
      });
  }, []);

  return (
    <div
      className="tinted-image"
      style={{
        background: `linear-gradient(rgba(251, 73, 19, 0.4), rgba(251, 73, 19, 0.1)),
      url("${background}")`,
      }}
    >
      <Card className={classes.card} radius="md">
        <Title className={classes.title} align="center" order={1} mb={30}>
          <div className="d-flex flex-column justify-content-start">
            <span>{t("FORGOT_PASSWORD")}</span>
            <Divider color="#343741" size="xl" mt={15} sx={{ width: 84 }} />
          </div>
        </Title>
        <Stack
          spacing={25}
          align="center"
          className="login-input-wrapper"
          justify="center"
          style={{ width: "75%" }}
        >
          <Input.Wrapper withAsterisk error={error && t("Invalid_Credentials")}>
            <TextInput
              value={username}
              onChange={(event) => setUsername(event.currentTarget.value)}
              placeholder={t("email")}
              // variant="unstyled"
              className={classes.input}
              required={true}
            />
          </Input.Wrapper>
          <Anchor
            className={classes.password}
            color="default.2"
            size={14}
            underline
            component={Link}
            to="/"
          >
            {t("BACK_TO")} {t("LOGIN")}
          </Anchor>
        </Stack>

        <Button
          loading={btnLoading}
          onClick={(e: any) => onSubmit(e)}
          styles={(theme) => ({
            root: { marginTop: 20 },
          })}
        >
          {t("SUBMIT")}
        </Button>

        {success && (
          <Alert
            icon={<IconAlertCircle size={16} />}
            title={t("SUCCESS")}
            color={"teal"}
            style={{ marginTop: "20px", width: "100%" }}
          >
            {t("RESET_PASSWORD_SENT")}
          </Alert>
        )}

        <Anchor
          onClick={() => setLang(lang === "ar" ? "en" : "ar")}
          variant="text"
          underline
          size={16}
          sx={{
            borderLeft: "2px solid white",
            fontFamily: "Cairo",
            marginTop: "15px",
          }}
        >
          {t("LANGUAGE")}
        </Anchor>
      </Card>
    </div>
  );
}

export default ForgotPassword;
